<template>
  <div id="scholarRz">
    <div class="title">认证 &nbsp;&gt;&nbsp;学者认证</div>
    <div class="list">
      <div style="position: relative" class="key">
        <span style="color: red; position: absolute; top: 3px; left: -15px"
          >*</span
        >姓名
      </div>
      <Input
        placeholder
        v-model="subData.userInfo.realname"
        style="width: 300px"
      />
    </div>
    <div class="list">
      <div style="position: relative" class="key">
        <span style="color: red; position: absolute; top: 3px; left: -15px"
          >*</span
        >头像
      </div>
      <!--      <Input v-model="name" placeholder="" style="width: 300px" />-->
      <Upload
        accept=".png,.jpg,.jpeg"
        :headers="{ token: token }"
        :show-upload-list="false"
        class="upload"
        key="coverImg"
        :format="['jpg', 'jpeg', 'png']"
        :max-size="5102"
        :action="`${$global.url1}/zw-public/web/common/upload`"
        :on-success="headPhotoSuccess"
      >
        <img
          class="img"
          v-if="subData.userInfo.headPhoto.length !== 0"
          v-lazy="$global.imgUrl + subData.userInfo.headPhoto"
          alt
        />
        <img v-else class="img" src="@/assets/img/userInfo/add.png" alt />
      </Upload>

      <div class="tishi">支持JPG、JPEG、PNG格式，图片不超过 5M</div>
    </div>
    <div class="list">
      <div style="position: relative" class="key">
        <span style="color: red; position: absolute; top: 3px; left: -15px"
          >*</span
        >学历/学位
      </div>
      <Cascader
        :data="educationOptions"
        :value="subData.userInfo.eduVal.split()"
        placeholder=""
        @on-change="educationChange"
      ></Cascader>
      <Checkbox
        style="margin-left: 20px"
        :disabled="educationDisabled"
        v-model="subData.userInfo.inStudy"
        >在读</Checkbox
      >
    </div>
    <div class="list">
      <div style="position: relative" class="key">
        <span style="color: red; position: absolute; top: 3px; left: -15px"
          >*</span
        >毕业院校
      </div>
      <Input
        placeholder
        v-model="subData.userInfo.school"
        style="width: 300px"
      />
    </div>
    <div class="list">
      <div style="position: relative" class="key">
        <span style="color: red; position: absolute; top: 3px; left: -15px"
          >*</span
        >现单位/院校
      </div>
      <Input placeholder v-model="subData.userInfo.unit" style="width: 300px" />
    </div>
    <div class="list">
      <div class="key">现任职位</div>
      <Input placeholder v-model="subData.userInfo.job" style="width: 300px" />
    </div>
    <div class="list">
      <div style="position: relative" class="key">
        <span style="color: red; position: absolute; top: 3px; left: -15px"
          >*</span
        >职称
      </div>
      <div
        class="leave"
        style="position: relative; width: 1000px; margin-top: 10px"
        v-for="(item, index) in leveList"
        :key="index"
      >
        <div :style="{ marginLeft: index !== 0 ? '160px' : '' }">
          <Select
            @click.native="one(item, index)"
            v-model="item.value1"
            style="width: 150px; margin-right: 10px"
          >
            <Option
              v-for="(obj, index) in item.historyList1"
              :key="index"
              :value="JSON.stringify(obj)"
              >{{ obj.seriesName }}</Option
            >
          </Select>
          <Select
            @click.native="two(item, index)"
            v-model="item.value2"
            style="width: 150px; margin-right: 10px"
            @on-change="handerChange(item, index)"
          >
            <Option
              v-for="(obj, index) in item.historyList2"
              :key="index"
              :value="JSON.stringify(obj)"
              >{{ obj.gradeName }}</Option
            >
          </Select>
          <Select
            v-model="item.value3"
            style="width: 150px; margin-right: 10px"
          >
            <Option
              v-for="(obj, index) in item.historyList3"
              :key="index"
              :value="JSON.stringify(obj)"
              >{{ obj.titleName }}</Option
            >
          </Select>
          <img
            v-if="leveList.length > 1"
            @click="removeItem(item, index)"
            class="addBtn subBtn"
            src="@/assets/img/userInfo/jianjianjian.png"
            alt
          />
          <img
            v-if="leveList.length === 1 || index === leveList.length - 1"
            @click="addLeave(item, index)"
            class="addBtn"
            src="@/assets/img/userInfo/jiajiajia.png"
            alt
          />
        </div>
      </div>
    </div>
    <div class="list">
      <div style="position: relative" class="key">
        <span style="color: red; position: absolute; top: 3px; left: -15px"
          >*</span
        >研究领域
      </div>
      <div
        class="leave"
        style="position: relative; width: 1000px; margin-top: 10px"
        v-for="(item, index) in subList"
        :key="index"
      >
        <div :style="{ marginLeft: index !== 0 ? '160px' : '' }">
          <Select
            @click.native="oneSub(item, index)"
            v-model="item.value1"
            style="width: 150px; margin-right: 10px"
          >
            <Option
              v-for="(obj, index) in item.historyList1"
              :key="index"
              :value="JSON.stringify(obj)"
              >{{ obj.subjectName }}</Option
            >
          </Select>
          <Select
            @click.native="twoSub(item, index)"
            v-model="item.value2"
            style="width: 150px; margin-right: 10px"
            @on-change="handerChangeSub(item, index)"
          >
            <Option
              v-for="(obj, index) in item.historyList2"
              :key="index"
              :value="JSON.stringify(obj)"
              >{{ obj.subjectName }}</Option
            >
          </Select>
          <Select
            v-model="item.value3"
            style="width: 150px; margin-right: 10px"
          >
            <Option
              v-for="(obj, index) in item.historyList3"
              :key="index"
              :value="JSON.stringify(obj)"
              >{{ obj.subjectName }}</Option
            >
          </Select>
          <img
            class="addBtn subBtn"
            v-if="subList.length > 1"
            @click="removeItemSub(item, index)"
            src="@/assets/img/userInfo/jianjianjian.png"
            alt
          />
          <img
            class="addBtn"
            v-if="subList.length === 1 || index === subList.length - 1"
            @click="addLeaveSub(item, index)"
            src="@/assets/img/userInfo/jiajiajia.png"
            alt
          />
        </div>
      </div>
    </div>
    <div class="list">
      <div style="position: relative" class="key">
        <span style="color: red; position: absolute; top: 3px; left: -15px"
          >*</span
        >身份证号
      </div>
      <Input
        placeholder
        v-model="subData.userInfo.idCard"
        style="width: 300px"
      />
      <input type="text" style="width: 0; opacity: 0; border: none" />
    </div>
    <div class="list" style="align-items: flex-start">
      <div style="position: relative" class="key">
        <span style="color: red; position: absolute; top: 3px; left: -15px"
          >*</span
        >身份证图片
      </div>
      <Upload
        accept=".png,.jpg,.jpeg"
        :headers="{ token: token }"
        :show-upload-list="false"
        class="upload"
        :format="['jpg', 'jpeg', 'png']"
        :max-size="5102"
        :action="`${$global.url1}/zw-public/web/common/upload`"
        :on-success="idCardFrontFrontSuccess"
      >
        <img
          class="IDimg"
          v-if="subData.userInfo.idCardFront.length !== 0"
          v-lazy="$global.imgUrl + subData.userInfo.idCardFront"
          alt
        />
        <img v-else class="IDimg" src="@/assets/img/userInfo/zid.png" alt />
      </Upload>
      <Upload
        accept=".png,.jpg,.jpeg"
        :headers="{ token: token }"
        :show-upload-list="false"
        style="margin-left: 20px"
        class="upload"
        :format="['jpg', 'jpeg', 'png']"
        :max-size="5102"
        :action="`${$global.url1}/zw-public/web/common/upload`"
        :on-success="idCardBackBackSuccess"
      >
        <img
          class="IDimg"
          v-if="subData.userInfo.idCardBack.length !== 0"
          v-lazy="$global.imgUrl + subData.userInfo.idCardBack"
          alt
        />
        <img v-else class="IDimg" src="@/assets/img/userInfo/fid.png" alt />
      </Upload>
      <!--      <Input v-model="operateIdentityCard" placeholder="" style="width: 300px" />-->
    </div>
    <div style="margin-left: 300px; margin-top: 20px" class="tishi">
      支持JPG、JPEG、PNG格式，图片不超过 5M
    </div>
    <div v-if="reviewStatus == '0'" class="close">审核中</div>
    <!-- <div v-else @click="sub" ></div> -->
    <div v-else style="text-align: center">
      <Button
        type="primary"
        shape="circle"
        class="submit"
        @click="sub"
        v-throttleClick="2000"
        >提交</Button
      >
    </div>
  </div>
</template>

<script>
import { subscholar, userEditTypes } from "../../../../apis/user";
import {
  getOneList,
  getThreeList,
  getTwoList,
  levelOne,
  levelThree,
  levelTwo,
} from "../../../../apis/public";
import { mapState } from "vuex";

export default {
  name: "scholarCertification",
  data() {
    return {
      leveList: [
        {
          value1: "",
          value2: "",
          value3: "",
          historyList1: [],
          historyList3: [],
          historyList2: [],
        },
      ],
      subList: [
        {
          value1: "",
          value2: "",
          value3: "",
          historyList1: [],
          historyList3: [],
          historyList2: [],
        },
      ],
      reviewStatus: 0,
      educationOptions: [],
      subData: {
        titles: [],
        subjects: [],
        userInfo: {
          realname: "",
          headPhoto: "",
          unit: "",
          education: "",
          eduVal: "",
          inStudy: false,
          school: "",
          job: "",
          idCard: "",
          idCardFront: "",
          idCardBack: "",
        },
      },
    };
  },
  computed: {
    ...mapState(["token", "userInfo"]),
    educationDisabled() {
      const secondId = this.subData.userInfo.eduVal.split(",")[1];
      return secondId === "5" || secondId === "8" || secondId === "10";
    },
  },
  created() {
    this.type();
    this.getEducationOptions();
  },
  methods: {
    async getEducationOptions() {
      let res = await this.$apis.userServe.educationOptions();
      this.educationOptions = res.data.map((item) => ({
        value: item.value,
        label: item.label,
        children: item.childrens,
      }));
    },
    educationChange(value, selectedData) {
      if (value.length) {
        if (value[1] == "5" || value[1] == "8" || value[1] == "10") {
          this.subData.userInfo.inStudy = false;
        }
        this.subData.userInfo.education = selectedData[1].label;
      } else {
        this.subData.userInfo.education = "";
        this.subData.userInfo.inStudy = false;
      }
      this.subData.userInfo.eduVal = value.join();
    },
    async type() {
      let res = await userEditTypes();
      this.reviewStatus = res.content.status;
    },
    verification() {
      if (this.subData["userInfo"].realname.length > 15) {
        this.$Message.error("姓名字数已达上限");
        return false;
      } else if (this.subData["userInfo"].job.length > 30) {
        this.$Message.error("现任职位字数已达上限");
        return false;
      } else if (this.subData["userInfo"].school.length > 30) {
        this.$Message.error("毕业院校字数已达上限");
        return false;
      } else if (this.subData["userInfo"].unit.length > 30) {
        this.$Message.error("现单位/院校字数已达上限");
        return false;
      } else if (this.subData["userInfo"].idCard.length > 30) {
        this.$Message.error("身份证号字数已达上限");
        return false;
      }
      if (this.subData["userInfo"].headPhoto == "") {
        this.$Message.error("头像不能为空");
        return false;
      } else if (this.subData["userInfo"].idCard == "") {
        this.$Message.error("身份证号不能为空");
        return false;
      } else if (this.subData["userInfo"].realname == "") {
        this.$Message.error("姓名不能为空");
        return false;
      } else if (this.subData["userInfo"].school == "") {
        this.$Message.error("毕业院校不能为空");
        return false;
      } else if (this.subData["userInfo"].unit == "") {
        this.$Message.error("现单位/院校不能为空");
        return false;
      } else if (this.subData["userInfo"].education == "") {
        this.$Message.error("最高学历不能为空");
        return false;
      } else if (
        this.subData["userInfo"].idCardBack == "" ||
        this.subData["userInfo"].idCardFront == ""
      ) {
        this.$Message.error("身份证图片不能为空");
        return false;
      }
      let reg =
        /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if (!reg.test(this.subData.userInfo.idCard)) {
        this.$Message.error("身份证格式错误");
        return false;
      }
      for (let i = 0; i < this.leveList.length; i++) {
        let item = this.leveList[i];
        if (this.leveList.length > 0) {
          if (item.value1 == "" || item.value2 == "" || item.value3 == "") {
            this.$Message.error("职称不能为空");
            return false;
          }
        }
      }
      for (let i = 0; i < this.subList.length; i++) {
        const item = this.subList[i];
        if (this.subList.length > 0) {
          if (item.value1 == "" || item.value2 == "" || item.value3 == "") {
            this.$Message.error("研究领域不能为空");
            return false;
          }
        }
      }
      return true;
    },
    async sub() {
      if (!this.verification()) {
        return;
      }
      if (this.userInfo.user.customerType === 1) {
        if (this.reviewStatus === -1 || this.reviewStatus === 3) {
          this.subData.titles = this.leveList.map((item) => {
            let value3 = item.value3 ? JSON.parse(item.value3) : null;
            let value2 = item.value2 ? JSON.parse(item.value2) : null;
            let value1 = item.value1 ? JSON.parse(item.value1) : null;
            if (item.value3) {
              let obj = {
                levelOne: value1.seriesId,
                levelTwo: value2.gradeId,
                levelThree: value3.id,
              };
              return obj;
            }
          });
          this.subData.subjects = this.subList.map((item) => {
            let value3 = item.value3 ? JSON.parse(item.value3) : null;
            let value2 = item.value2 ? JSON.parse(item.value2) : null;
            let value1 = item.value1 ? JSON.parse(item.value1) : null;
            if (item.value3) {
              let obj = {
                levelOne: value1.id,
                levelTwo: value2.id,
                levelThree: value3.id,
              };
              return obj;
            }
          });
          let res = await subscholar(this.subData);
          if (res.data.resultCode == "200") {
            this.$Message.success(res.data.resultDesc);
            this.$router.push("/user/attestation");
          } else {
            this.$Message.error(res.data.resultDesc);
          }
        } else {
          this.$Message.success("正在审核中");
          return;
        }
      } else {
        this.$Message.error("当前用户不能提交认证");
      }
    },
    //图片
    idCardFrontFrontSuccess(res, file) {
      console.log(res, file, "res, file");
      this.subData.userInfo.idCardFront = res.content.url;
    },
    idCardBackBackSuccess(res, file) {
      console.log(res, file, "res, file");
      this.subData.userInfo.idCardBack = res.content.url;
    },
    headPhotoSuccess(res, file) {
      console.log(res, file, "res, file");
      // this.url = res.content.url;
      this.subData.userInfo.headPhoto = res.content.url;
    },
    //研究领域s
    addLeaveSub(e, i) {
      console.log(e, i);
      this.subList.push({
        value1: "",
        value2: "",
        value3: "",
        historyList1: [],
        historyList3: [],
        historyList2: [],
      });
    },
    removeItemSub(e, i) {
      console.log(e);
      this.subList.splice(i, 1);
    },
    oneSub(e, i) {
      console.log(e, i);
      this.getsubData(i);
    },
    async getsubData(i) {
      let res = await getOneList();
      console.log(res);
      this.subList[i].historyList1 = res.researchOneList;
    },
    async openChanggeSub(e, i) {
      console.log(e);
      let value = JSON.parse(e.value2);
      let obj = { prevSubjectId: value.id };
      let res = await getThreeList(obj);
      this.subList[i].historyList3 = res.content.researchThreeList;
    },
    handerChangeSub(e, i) {
      this.openChanggeSub(e, i);
    },
    async twoSub(e, i) {
      console.log(e.value1);
      let value = JSON.parse(e.value1);
      let res = await getTwoList({
        prevSubjectId: value.id,
      });
      this.subList[i].historyList2 = res.content.researchTwoList;
    },
    //职称
    addLeave(e, i) {
      console.log(e, i);
      this.leveList.push({
        value1: "",
        value2: "",
        value3: "",
        historyList1: [],
        historyList3: [],
        historyList2: [],
      });
    },
    removeItem(e, i) {
      console.log(e);
      this.leveList.splice(i, 1);
    },
    one(e, i) {
      console.log(e, i);
      this.getleveData(i);
    },
    async openChangge(e, i) {
      let value = JSON.parse(e.value2);
      let res = await levelThree({
        seriesId: value.seriesId,
        gradeId: value.gradeId,
      });
      this.leveList[i].historyList3 = res.content.titleThreeList;
      // this.$set(e, "children", res.content.titleThreeList);
      // e.children = res.content.titleThreeList;
    },
    handerChange(e, i) {
      this.openChangge(e, i);
    },
    async getleveData(i) {
      let res = await levelOne();
      this.leveList[i].historyList1 = res.content.titleOneList;
    },
    async two(e, i) {
      console.log(e.value1);
      let value = JSON.parse(e.value1);
      let res = await levelTwo({
        seriesId: value.seriesId,
      });
      this.leveList[i].historyList2 = res.content.titleTwoList;
    },
  },
};
</script>

<style lang="scss" scoped>
#scholarRz {
  padding: 40px;
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 10px;

  .submit {
    //margin-left: 13px;
    text-align: center;
    margin: 50px auto 0;
    line-height: 34px;
    width: 70px;
    height: 36px;
    color: #fff;
    background: #007fff;
    border-radius: 8px;
    cursor: pointer;
  }
  .close {
    text-align: center;
    margin: 50px auto 0;
    line-height: 38px;
    width: 70px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 8px;
    cursor: pointer;
  }
  .title {
    height: 20px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    margin-bottom: 70px;
  }

  .list {
    display: flex;
    align-items: center;
    margin-top: 10px;
    flex-wrap: wrap;
    width: 100%;
    border-top: 1px solid #e6e6e6;
    padding-top: 30px;
    padding-bottom: 30px;
    //height: 100px;
    .upload {
      display: inline-block;
    }

    .leave {
      width: 600px;
      .addBtn {
        width: 15px;
        height: 15px;
        cursor: pointer;
        vertical-align: middle;
      }
      .subBtn {
        margin-right: 10px;
      }
    }

    .img {
      width: 68px;
      height: 68px;
      margin-right: 20px;
    }

    .IDimg {
      width: 296px;
      height: 163px;
      //background: pink;
      border-radius: 6px;
    }

    .key {
      //background: pink;
      font-size: 14px;
      width: 140px;
      margin-right: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 48px;
    }
  }
}
</style>
